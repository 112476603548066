export const navigatorLinks = {
	about: '/about',
	products: '/products',
	ai: '/ai',
	career: 'https://career.tesser.io',
	labeling: '/labeling',
	contact: '/contact',
	news: 'https://blog.tesser.io/tag/news/',
	dataVoucher: '/dataVoucher',
} as const
