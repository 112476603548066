import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { window, document } from 'browser-monads'
import { useTranslation } from 'react-i18next'

import Typography from '../../common/Typography'
import zIndexes from '../../../lib/styles/zIndexes'
import { ColorType } from '../../../context/InvertColorsContext'
import storage from '../../../lib/utils/storage'
import { down } from 'styled-breakpoints'
import { modifyLangString } from '../../../lib/utils/modifyLangString'

interface LeftFloatingBarProps {
	itemColor: ColorType
}
function LeftFloatingBar({ itemColor }: LeftFloatingBarProps) {
	const { i18n } = useTranslation()
	const initialLanguage =
		modifyLangString(i18n.language) ?? storage.getItem('language')

	const [language, setLanguage] = useState(initialLanguage)
	const [hide, setHide] = useState(false)
	const [pageY, setPageY] = useState(0)
	const documentRef = useRef(document)
	const [showScroll, setShowScroll] = useState(false)

	const handleScroll = () => {
		const { pageYOffset } = window
		const deltaY = pageYOffset - pageY
		const hidden = pageYOffset !== 0 && deltaY >= 0
		setHide(hidden)
		setPageY(pageYOffset)
	}

	useEffect(() => {
		documentRef.current.addEventListener('scroll', handleScroll)
		return () => documentRef.current.removeEventListener('scroll', handleScroll)
	}, [pageY])

	useEffect(() => {
		documentRef.current.addEventListener('scroll', checkScrollTop)
		return () =>
			documentRef.current.removeEventListener('scroll', checkScrollTop)
	}, [])

	const SCROLL_VALUE = 30

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 200) {
			setShowScroll(true)
		} else if (showScroll && window.pageYOffset <= 200) {
			setShowScroll(false)
		}
	}
	// 언어 변경 함수
	const changeLanguage = () => {
		const newLang = language === 'en' ? 'ko' : 'en'
		setLanguage(newLang)
		i18n.changeLanguage(newLang)
		storage.setItem('language', newLang)
	}

	return (
		<Block>
			<Hide hide={hide && pageY > SCROLL_VALUE}>
				<Rotate>
					<CopyRight itemColor={itemColor}>
						<Typography.Body size="small">
							&copy; 2025 Tesser inc.
						</Typography.Body>
						<Typography.Body size="small">All Rights Reserved</Typography.Body>
					</CopyRight>

					<LanguageOption onClick={changeLanguage}>
						{language === 'ko' && (
							<>
								<SelectedLangTypo>KOR</SelectedLangTypo>
								<UnSelectedLangTypo>ENG</UnSelectedLangTypo>
							</>
						)}
						{language === 'en' && (
							<>
								<SelectedLangTypo>ENG</SelectedLangTypo>
								<UnSelectedLangTypo>KOR</UnSelectedLangTypo>
							</>
						)}
					</LanguageOption>
				</Rotate>
			</Hide>
		</Block>
	)
}

const Block = styled.div`
	position: fixed;
	bottom: 12rem;
	width: 16.25rem;
	transform: translateX(-72%);
	z-index: ${zIndexes.leftBar};

	@media (max-width: 1088px) {
		left: 80px;
	}

	${down('sm')} {
		display: none;
	}
`

const Hide = styled.div<{ hide: boolean }>`
	transition: all 0.5s;
	${(props) =>
		props.hide &&
		css`
			opacity: 0;
			transform: translateX(-1rem);
		`}
`

const Rotate = styled.div`
	transform: rotate(270deg);
	display: flex;
	justify-content: space-between;
`

const CopyRight = styled.div<{ itemColor: ColorType }>`
	${({ itemColor }) => {
		if (itemColor === ColorType.BLACK) {
			return css`
				color: black;
			`
		} else {
			return css`
				color: white;
			`
		}
	}}
`

const SelectedLangTypo = styled.p`
	color: black;
	border-bottom: 1px solid black;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

const UnSelectedLangTypo = styled.p`
	color: #aaa;
	border-bottom: 1px solid transparent;
	font-size: 0.875rem;
	line-height: 1.25rem;
`

const LanguageOption = styled.div`
	cursor: pointer;
`

export default LeftFloatingBar
