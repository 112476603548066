import React, { useCallback } from 'react'
import { globalHistory } from '@reach/router'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Typography from '../../common/Typography'
import {
	animateBorderBottom,
	animateBorderBottomWhite,
} from '../../../lib/styles/utils'
import LinkWrapper from '../../common/LinkWrapper'
import { navigatorLinks } from '../../../lib/data/navigatorLinks'
import breakpoints from '../../../lib/styles/breakpoints'
import { only, up } from 'styled-breakpoints'
import { modifyLangString } from '../../../lib/utils/modifyLangString'

interface NavType {
	name: string
	link: string
}

interface RightNavigationProps {
	blackBackground?: boolean
}

function RightNavigation({ blackBackground }: RightNavigationProps) {
	// const { t, i18n } = useTranslation()
	const { t, i18n } = useTranslation()	
	
	const isActive = useCallback(
		(currentPage: string) => {
			const [, pathname] = globalHistory.location.pathname.split('/')
			const [, pagename] = currentPage.split('/')
			
			if (!pathname) {
				return false
			}
			
			return pathname === pagename ? true : false
		},
		[globalHistory.location.pathname]
	)
	
	const initialLanguage = typeof window !== 'undefined' ? 
		localStorage.getItem('language')
			? localStorage.getItem('language')
			: 'ko'
		: 'ko'

	const navigation: Array<NavType> = [
		{ name: t('header.header2'), link: navigatorLinks.products },
		{ name: t('header.header3'), link: navigatorLinks.ai },
		{ name: t('header.header1'), link: navigatorLinks.about },
		{ name: t('header.header4'), link: navigatorLinks.career },
		// ...(modifyLangString(i18n.language) === 'en'
		// 	? []
		// 	: [{ name: t('header.header5'), link: navigatorLinks.labeling }]),
		...(i18n.language == 'en' && initialLanguage === 'en'
			? []
			: [{ name: t('header.dataVoucher'), link: navigatorLinks.dataVoucher }]),
		{ name: t('header.header6'), link: navigatorLinks.contact },
	]

	return (
		<Block>
			<ul>
				{navigation.map(({ name, link }: NavType) => {
					const active = isActive(link)
					// if (name === 'Data Voucher') {
					// 	return
					// }
					return (
						<Item key={name} active={active} blackBackground={blackBackground}>
							<LinkWrapper to={link} target="_blank">
								<Typography.Heading
									size="small"
									fontWeight="bold"
									color={blackBackground === true ? 'dark00' : 'dark100'}
								>
									{name}
								</Typography.Heading>
							</LinkWrapper>
						</Item>
					)
				})}
			</ul>
		</Block>
	)
}

const Block = styled.nav`
	position: relative;

	align-self: flex-end;
	white-space: nowrap;

	> ul {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	${up('md')} {
		right: -7vw;
	}

	${only('md')} {
		right: -3.5vw;
	}
`

const Item = styled.li<{ active: boolean; blackBackground?: boolean }>`
	list-style: none;
	margin-bottom: 0.25rem;

	${(props) =>
		props.blackBackground === true
			? animateBorderBottomWhite
			: animateBorderBottom};

	${(props) =>
		props.active &&
		css`
			&::after {
				display: block;
				content: '';
				height: 3px;
				background: ${props.blackBackground === true ? '#fff' : '#000'};
				transition: width 0.2s ease-in-out;
				width: 100%;
			}
		`}
`

export default RightNavigation
